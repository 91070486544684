import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { ProductionPage } from './pages/production/ProductionPage';
import { DashboardPage } from './pages/dashboard/DashboardPage.js';
import { TrialHomePage } from './pages/trial/TrialHomePage';
import { TrialSetupPage } from './pages/trial/TrialSetupPage';
import { TrialMobilePage } from './pages/trial/TrialMobilePage';
import { TrialLivePage } from './pages/demo/TrialLivePage.js';
import { Profile } from './pages/settings/profile/Profile';
import { CoreLayout } from './components/layout/CoreLayout.js';
import { TrialLayout } from './components/layout/TrialLayout.js';
import { SalesPage } from './pages/sales/SalesPage.js';
import { ProtectedRoute } from './components/routes/ProtectedRoute.js';
import { LogLayout } from './components/layout/LogLayout.js';
import { ResetPasswordPage } from './pages/reset-password/ResetPasswordPage.js';
import Issue from './assets/images/issue.jpg';
import { OperatorPage } from './pages/operator/OperatorPage.js';
import { OperatorWorkstationPage } from './pages/operator/OperatorWorkstationPage.js';
import { isMobileDevice } from './helpers/common/isMobileDevice.js';
import { Overview } from './pages/dashboard/Overview.js';
import { Workshop } from './pages/dashboard/Workshop.js';
import { Workstation } from './pages/dashboard/Workstation.js';
import { SetupPage } from './pages/setup/SetupPage.js';
import { SpecificationPage } from './pages/setup/SpecificationPage.js';
import { CalibrationPage } from './pages/calibration/CalibrationPage.js';
import { ErgonomicsPage } from './pages/ergonomics/ErgonomicsPage';

export const App = () => {
  const location = useLocation();

  const mobilePages = ['/demo/stream', '/calibration'];
  const isTrialMobilePage = mobilePages.some((page) => location.pathname.includes(page));

  if (isMobileDevice() && !isTrialMobilePage) return <ResponsiveFallbackComponent />;

  return (
    <Routes>
      <Route element={<CoreLayout />}>
        <Route path='/' element={<Navigate to='/production' replace />} />

        {/* Trial pages. */}
        <Route element={<TrialLayout />}>
          <Route path='/demo/setup' element={<TrialSetupPage />} />
          <Route path='/demo' element={<TrialHomePage />} />
          <Route path='/demo/stream/:desktopId' element={<TrialMobilePage />} />
          <Route path='/demo/live' element={<TrialLivePage />} />
        </Route>

        {/* Page requiring connection to the engine. */}
        <Route element={<LogLayout />}>
          <Route path='/production' element={<ProductionPage />} />
          <Route path='/production/:subtype' element={<ProductionPage />} />
          <Route path='/register/:organization_token' element={<ProductionPage />} />
          <Route path='/reset-password/:token' element={<ResetPasswordPage />} />
        </Route>
        {/* Setup pages. */}
        <Route path='/setup' element={<SetupPage />} />
        <Route path='/setup/:subtype' element={<SetupPage />} />
        <Route path='/specification/:deviceId' element={<SpecificationPage />} />

        {/* Dashboard pages. */}
        <Route path='/dashboard' element={<DashboardPage />}>
          <Route path='overview' element={<Overview />} />
          <Route path='workshop' element={<Workshop />} />
          <Route path='workstation' element={<Workstation />} />
          <Route path='workstation/:workstationId/:workshopId/:startDate/:endDate' element={<Workstation />} />
        </Route>

        {/* Commons pages. */}
        <Route path='/settings' element={<Outlet />}>
          <Route path='profile' element={<Profile />} />
        </Route>

        {/* Protected and sensitive pages */}
        <Route
          path='/backoffice'
          element={
            <ProtectedRoute requiredRole='ROLE_ROOT'>
              <SalesPage />
            </ProtectedRoute>
          }
        />

        {/* IHM operator pages */}
        <Route path='/operator' element={<OperatorPage />} />
        <Route path='/operator/:workstationId' element={<OperatorWorkstationPage />} />
        <Route path='*' element={<Navigate to='/production' replace />} />

        {/* Ergonomics pages */}
        <Route path='/ergonomics' element={<ErgonomicsPage />} />

        {/* Camera calibration page */}
        <Route path='/calibration/:deviceToken1/:deviceToken2' element={<CalibrationPage />} />
      </Route>
    </Routes>
  );
};

const ResponsiveFallbackComponent = () => {
  return (
    <div className='fixed top-0 left-0 flex flex-col items-center justify-center w-full h-full px-4 md:flex-row'>
      <img src={Issue} className='w-2/3 h-auto md:w-2/4' alt='issue, this website is not optimized for mobile' />
      <div className='max-w-md p-6 text-center bg-white rounded-lg md:text-left'>
        <h1 className='mb-2 text-2xl font-bold'>Oops!</h1>
        <p className='mb-2'>This site is not optimized for mobile devices.</p>
        <p>Please open it on a desktop or laptop computer for a better experience.</p>
        <div className='mt-4'>
          <a
            href='https://www.linkedin.com/company/perception-manufacturing'
            rel='noopener noreferrer'
            className='px-4 py-2 font-bold text-white rounded bg-perception-blue hover:bg-blue-700'
          >
            Contact us
          </a>
        </div>
      </div>
    </div>
  );
};
