import { isFreeUser, isTeamUser } from '../../services/entity/user/user-service';

const settingsPath = '/settings/';

/**
 * Get general navigation menu
 */
export const getGeneralNavigationMenu = (user) => {
  return [
    {
      name: 'DEMO',
      icon: 'camera',
      href: 'demo/live',
      isVisible: isFreeUser(user) || isTeamUser(user),
      isActive: isMenuItemValid('demo/live'),
      isEnabled: true,
    },
    {
      name: 'PRODUCTION',
      icon: 'precision_manufacturing',
      href: 'production',
      isVisible: true,
      isActive: isMenuItemValid('production') || isHomePage(),
      isEnabled: !isFreeUser(user) || isTeamUser(user),
    },
    {
      name: 'DASHBOARD',
      icon: 'dashboard',
      href: 'dashboard',
      isVisible: true,
      isActive: isMenuItemValid('dashboard'),
      isEnabled: !isFreeUser(user) || isTeamUser(user),
    },
    {
      name: 'SETUP',
      icon: 'source',
      href: 'setup',
      isVisible: true,
      isActive: isMenuItemValid('setup'),
      isEnabled: !isFreeUser(user) || isTeamUser(user),
    },
    {
      name: 'OPERATOR',
      icon: 'build',
      href: 'operator',
      isVisible: true,
      isActive: isMenuItemValid('operator'),
      isEnabled: !isFreeUser(user) || isTeamUser(user),
    },
    {
      name: 'ERGONOMICS',
      icon: 'directions_walk',
      href: 'ergonomics',
      isVisible: true,
      isActive: isMenuItemValid('ergonomics'),
      isEnabled: !isFreeUser(user) || isTeamUser(user),
    },
    {
      name: 'ADMIN',
      icon: 'storage',
      href: 'backoffice',
      isVisible: true,
      isActive: isMenuItemValid('backoffice'),
      isEnabled: !isFreeUser(user) || isTeamUser(user),
    },
  ];
};

/**
 * Get heading navigation menu for settings' pages
 */
export const getSettingsNavigationMenu = () => {
  return [
    {
      name: 'Profile',
      icon: 'person',
      href: settingsPath + 'profile',
      current: isMenuItemValid(settingsPath + 'profile'),
    },
  ];
};

/**
 * Check if a menu item corresponds to the active page
 */
export const isMenuItemValid = (path) => {
  return window.location.pathname.includes(path);
};

/**
 * Check if the active page is the home page
 */
export const isHomePage = () => {
  return window.location.pathname === '/';
};
